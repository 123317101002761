import { Suspense } from 'react';
import { render } from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { convertFromRaw, EditorState } from 'draft-js';
import StoryPreviewContainer from './components/StoryPreviewContainer';
import { storyReducers } from '@/stories/reducers';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import StoryPreviewNavigationPortal from '@/preview/portals/StoryPreviewNavigationPortal';
import {
  previewCanCommentVar,
  previewEnabledVar,
  previewModelSignKeysVar,
  previewUserSignKeyVar,
  previewUsersVar,
  storyEditorDataVar,
} from '@/graphql/cache';
import StoryChief from '@/storychief';
import EntitlementTeaser from '@/entitlements/components/EntitlementTeaser';
import CommentsProvider from '@/comments/components/CommentsProvider';
import lazyWithRetryPolicy from '@/storychief/utils/lazyWithRetryPolicy';

const App = lazyWithRetryPolicy(() => import('@/storychief/components/App'));

const storyPreviewNode = document.getElementById('story-preview');
const storyPreviewNavigationNode = document.getElementById('story-preview-navigation');

if (storyPreviewNode && storyPreviewNavigationNode) {
  const story = JSON.parse(storyPreviewNode.dataset.story);
  const users = JSON.parse(storyPreviewNode.dataset.users);
  const author = JSON.parse(storyPreviewNode.dataset.author);
  const destinations = JSON.parse(storyPreviewNode.dataset.destinations);
  const editUrl = storyPreviewNavigationNode.dataset.editUrl;
  const exportPdfEndpoint = storyPreviewNode.dataset.exportPdfEndpoint;
  const exportDocxEndpoint = storyPreviewNode.dataset.exportDocxEndpoint;
  const exportZipEndpoint = storyPreviewNode.dataset.exportZipEndpoint;
  const fetchEndpoint = storyPreviewNode.dataset.fetchEndpoint;
  const userSignKey = storyPreviewNode.dataset.userSignKey;
  const modelSignKey = storyPreviewNode.dataset.modelSignKey;
  const canComment = !!StoryChief.user;

  let editorState;
  if (story.content !== '') {
    const contentState = convertFromRaw(JSON.parse(story.content));
    editorState = EditorState.createWithContent(contentState, getDefaultDecorator());
  } else {
    editorState = EditorState.createEmpty(getDefaultDecorator());
  }

  let titleEditorState;
  const storyTitleObj = JSON.parse(story.title);
  if (storyTitleObj.blocks[0].text === 'Untitled article') {
    const titleRaw = {
      entityMap: {},
      blocks: [
        {
          key: 'title',
          text: '',
          type: 'header-one',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    };
    const titleContentState = convertFromRaw(titleRaw);
    titleEditorState = EditorState.createWithContent(titleContentState, getDefaultDecorator());
  } else {
    const titleContentState = convertFromRaw(storyTitleObj);
    titleEditorState = EditorState.createWithContent(titleContentState, getDefaultDecorator());
  }

  const store = createStore(
    storyReducers,
    {
      story,
      destinations,
      users,
      editor: {
        editorState,
        titleEditorState,
      },
      previewAuthor: author,
    },
    applyMiddleware(thunk),
  );

  // This is for users who are anonymous who want to comment
  previewEnabledVar(true);
  previewUserSignKeyVar(userSignKey);
  previewModelSignKeysVar({ Story: { [story.id]: modelSignKey } });
  previewCanCommentVar(canComment);
  previewUsersVar(users);
  storyEditorDataVar({
    editUrl,
    exportPdfEndpoint,
    exportDocxEndpoint,
    exportZipEndpoint,
    fetchEndpoint,
  });

  render(
    <Suspense fallback={null}>
      <App store={store} includeHashRouter>
        <EntitlementTeaser entitlement="stories">
          <CommentsProvider modelId={`${story.id}`} modelType="Story">
            <StoryPreviewContainer />
            <StoryPreviewNavigationPortal editUrl={editUrl} />
          </CommentsProvider>
        </EntitlementTeaser>
      </App>
    </Suspense>,
    storyPreviewNode,
  );
}
