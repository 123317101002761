import { JSX, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EditorPreviewSc from '@/editor/components/EditorPreviewSc';
import StoryPreviewSettings from './StoryPreviewSettings';
import setIsPreview from '@/storychief/actions/setIsPreview';
import StoryErrors from '@/stories/components/StoryErrors';
import { usePrevious } from '@/storychief/hooks';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';
import useStoryEditorOffset from '@/stories/hooks/useStoryEditorOffset';
import useStorySidebarOpenOnLoad from '@/stories/hooks/useStorySidebarOpenOnLoad';
import useSelectedComment from '@/comments/hooks/useSelectedComment';
import useComments from '@/comments/hooks/useComments';
import { commentsUnsavedVar } from '@/graphql/cache';
import useFragment from '@/storychief/hooks/useFragment';
import currentUserHasPendingReview from '@/reviews/utility/currentUserHasPendingReview';
import { EditorCollaborationContextProvider } from '@/editor/contexts/EditorCollaborationContext';

type StoryPreviewContainerType = {
  storyId: string;
  uiStatus: string;
  setIsPreview: () => void;
};

function StoryPreviewContainer(props: StoryPreviewContainerType): JSX.Element {
  const { storyId, uiStatus } = props;

  const { data: publicStory, complete } = useFragment({
    fragment: useStorySidebarOpenOnLoad.fragments.story,
    id: storyId,
  });
  const isOpenReview = useMemo<boolean>(
    () => currentUserHasPendingReview(publicStory),
    [publicStory],
  );

  // Hooks
  const { isCommentsSetupDone, comments } = useComments();
  const containerRef = useRef(null);
  const storyEditorSidebars = useStoryEditorSidebars();
  const activeSidebar = storyEditorSidebars.data?.[0]?.id;
  const prevActiveSidebar = usePrevious(activeSidebar);
  const editorOffset = useStoryEditorOffset();
  const { setSelectedCommentEditorKey } = useSelectedComment();
  useStorySidebarOpenOnLoad({
    storyId,
    isOpenReview,
    hasBrief: !!publicStory?.brief,
    loading: !complete,
    isPreview: true,
  });

  // Effects
  useEffect(() => {
    props.setIsPreview();
  }, []);

  useEffect(() => {
    props.setIsPreview();
    window.addEventListener('beforeunload', onBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeunload);
    };
  }, [comments, uiStatus]);

  useEffect(() => {
    if (prevActiveSidebar === 'CommentsSidebar' && activeSidebar !== 'CommentsSidebar') {
      setSelectedCommentEditorKey(null);
    }
  }, [activeSidebar]);

  // Functions
  function onBeforeunload(event) {
    const e = event;

    if (uiStatus !== 'error' && commentsUnsavedVar().size > 0) {
      e.preventDefault();
      e.returnValue = 'Your changes have not been saved, are you sure you want to exit?';
    }

    return e;
  }

  // Render
  if (!isCommentsSetupDone) {
    return null;
  }

  return (
    <div className="story-edit-container">
      <EditorCollaborationContextProvider modelType="Story" modelId={storyId}>
        <div style={{ paddingRight: editorOffset }} ref={containerRef}>
          <Route
            render={({ location: routeLocation }) => {
              // show when not settings or channels,
              // this fixes the issue with anchor links inside story
              if (
                routeLocation.pathname.indexOf('/settings') === -1 &&
                routeLocation.pathname.indexOf('/channels') === -1
              ) {
                return (
                  <div className="space-top-8" data-editor-container="">
                    <EditorPreviewSc />
                  </div>
                );
              }
              return null;
            }}
          />
          <Route
            path="/settings"
            render={() => (
              <div className="story-edit-settings-container">
                <StoryPreviewSettings storyId={storyId} />
              </div>
            )}
          />
        </div>
      </EditorCollaborationContextProvider>
      <StoryErrors />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    storyId: state.story.id.toString(),
    uiStatus: state.ui.status,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setIsPreview,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryPreviewContainer);
