import { gql, useQuery } from '@apollo/client';
import React, { JSX, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import legacyToggleModal from '@/storychief/actions/toggleModal';
import StoryChief from '@/storychief/index';
import getReadTime from '@/storychief/utils/getReadTime';
import StoryPreviewNavigationItems from './StoryPreviewNavigationItems';
import { getStoryWordCount } from '@/stories/selectors';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import ReviewButton from '@/reviews/components/ReviewsButton';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';
import useStoryEditorData from '@/stories/hooks/useStoryEditorData';
import CommentsButton from '@/comments/components/CommentsButton';
import CommentsProvider from '@/comments/components/CommentsProvider';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';
import Review from '@/reviews/components/Review';
import useModal from '@/modals/hooks/useModal';
import useUserSignKey from '@/storychief/hooks/useUserSignKey.tsx';

const STORY_QUERY = gql`
  query ExternalStoryEditNavigationQuery($id: ID!) {
    story: publicStory(id: $id) {
      __typename
      id
      title
      language
      created_at
      updated_at
      deleted_at

      brief {
        __typename
        id
      }

      story_destinations(trashed: WITH) {
        __typename
        id
        published_at
      }

      # Reviews
      ...ReviewModel
      reviews {
        ...Review
      }

      edit_url
    }
  }
  ${Review.fragments.model}
  ${Review.fragments.review}
`;

type StoryPreviewNavigationProps = {
  toggleModal: (id: string) => void;
  storyWordCount: number;
};

function StoryPreviewNavigation({
  toggleModal,
  storyWordCount,
}: StoryPreviewNavigationProps): JSX.Element {
  const storyId = useSelector((state) => state.story.id.toString());
  const readTime = getReadTime(storyWordCount);

  // Hooks
  const userSignKey = useUserSignKey();
  const modelSignKey = useModelSignKey({ typename: 'Story', id: storyId });

  // Queries
  const {
    data: { story } = {
      story: {
        title: null,
        brief: null,
        story_destinations: [],
        reviews: [],
        approval_status: null,
        total_reviews: 0,
      },
    },
  } = useQuery(STORY_QUERY, {
    variables: {
      id: storyId,
    },
    context: {
      credentials: 'omit',
      headers: {
        ...userSignKey,
        ...modelSignKey,
      },
    },
  });

  const isPublished = story.story_destinations.some((sd) => sd.published_at);

  // Hooks
  const storyEditorSidebars = useStoryEditorSidebars();
  const storyEditorData = useStoryEditorData();
  const storyExportModal = useModal('StoryExportModal');

  // Effects
  useEffect(() => {
    if (/download/.exec(window.location.href)) {
      storyExportModal.toggle();
    }
  }, []);

  // Functions
  function openBriefSidebar() {
    storyEditorSidebars.replace({
      id: 'StoryBriefSidebar',
    });
  }

  function openCommentsSidebar() {
    storyEditorSidebars.replace({
      id: 'CommentsSidebar',
      props: {
        modelId: storyId,
        modelType: 'Story',
      },
    });
  }

  function openApprovalSidebar() {
    storyEditorSidebars.replace({
      id: 'ReviewsSidebar',
      props: {
        modelId: storyId,
        modelType: 'Story',
      },
    });
  }

  function handleOnLogin() {
    window.location.href = StoryChief.dashboardBasePath;
  }

  function handleOnStoryEdit() {
    window.location.href = storyEditorData.editUrl;
  }

  // Render
  return (
    <div>
      <nav className="navbar navbar-story-edit">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="nav-wrapper navbar-brand" href={`${StoryChief.dashboardBasePath}/home`}>
              {StoryChief.account.agency_logo ? (
                <img alt={StoryChief.account.name} src={StoryChief.account.agency_logo} />
              ) : (
                <img alt="StoryChief" src={StoryChief.logo} />
              )}
            </a>
            <p className="navbar-text-2 nav-wrapper navbar-text-first hidden-xs">
              <span id="story-title" className="d-block text-ellipsis">
                <span className="label label-info">Preview</span>
                <span className="gutter-right-1" />
                {!isPublished && <span className="text-muted thin">Draft | </span>}
                <strong className="text-bolder">
                  {getTitlePlainText(story.title) || 'Untitled article'}
                </strong>
              </span>
              <span className="small">
                <span className="text-muted thin">Words </span>
                <strong id="word-count" className="text-muted text-bolder">
                  {storyWordCount}
                </strong>
                <span className="text-muted thin"> - Read time </span>
                <strong id="read-time" className="text-muted text-bolder">
                  {readTime}
                </strong>
                <strong className="text-muted text-bolder"> min</strong>
              </span>
            </p>
            <Button
              onClick={() => toggleModal('StoryExportModal')}
              bsStyle="info"
              className="btn--rectangle btn-small visible-xs-inline"
            >
              Download
            </Button>
            {!!story.brief && (
              <button
                type="button"
                className="btn btn-link visible-xs navbar-text gutter-2 pull-right"
                onClick={openBriefSidebar}
              >
                Brief
              </button>
            )}
            {StoryChief.user?.role && (
              <a href={story.edit_url} className="visible-xs navbar-text gutter-2 pull-right">
                Edit
              </a>
            )}
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              {story.total_reviews > 0 && (
                <li>
                  <ReviewButton
                    approval_status={story.approval_status}
                    onClick={openApprovalSidebar}
                  />
                </li>
              )}
              <li>
                <CommentsProvider modelId={storyId} modelType="Story">
                  <CommentsButton
                    onClick={openCommentsSidebar}
                    className="btn btn-default btn-outline btn-small hidden-xs"
                  />
                </CommentsProvider>
              </li>
              <li className="dropdown hidden-xs">
                <button
                  id="dropdown-more-toggle"
                  className="dropdown-toggle btn-chromeless btn-link"
                  data-toggle="dropdown"
                  type="button"
                  aria-expanded="false"
                >
                  <Icon icon="bi:three-dots" inline rotate="90deg" />
                </button>
                <ul id="dropdown-more-menu" className="dropdown-menu" role="menu">
                  <li>
                    {StoryChief.user?.role ? (
                      <button className="btn btn-link" type="button" onClick={handleOnStoryEdit}>
                        Edit this article
                      </button>
                    ) : (
                      <button className="btn btn-link" type="button" onClick={handleOnLogin}>
                        Login to StoryChief
                      </button>
                    )}
                  </li>
                  {!!story.brief && (
                    <li>
                      <button className="btn btn-link" type="button" onClick={openBriefSidebar}>
                        Brief
                      </button>
                    </li>
                  )}
                  <li>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => storyExportModal.toggle()}
                    >
                      Download
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav className="navbar navbar-story-edit-navigation hidden-xs">
        <div className="nav-wrapper nav-wrapper-center">
          <StoryPreviewNavigationItems storyId={storyId} />
        </div>
      </nav>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    storyWordCount: getStoryWordCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleModal: legacyToggleModal,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryPreviewNavigation);
