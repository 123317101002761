import { JSX, useRef } from 'react';
import AutoAffix from 'react-overlays/AutoAffix';
import { animateScroll } from 'react-scroll';
import Scrollspy from 'react-scrollspy';

import DetailsBlock from '@/stories/components/settings/DetailsBlock';
import CustomFieldsBlock from '@/stories/components/settings/CustomFieldsBlock';
import useStoryCustomFields from '@/stories/hooks/useStoryCustomFields';

type StoryPreviewSettingsType = {
  storyId: string;
};

function StoryPreviewSettings({ storyId }: StoryPreviewSettingsType): JSX.Element {
  const wrapper = useRef(null);
  const { customFields, isCustomFieldsInitialized } = useStoryCustomFields(storyId, true);

  function handleSubNavClick(section: string) {
    const scrollTo =
      document.getElementById(section).getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      120;
    animateScroll.scrollTo(scrollTo);
  }

  return (
    <div className="container-fluid" ref={wrapper}>
      <div className="row">
        <div className="col-sm-2">
          <AutoAffix viewportOffsetTop={120} container={() => wrapper.current}>
            <div className="panel panel-default panel-side-menu hidden-xs">
              <div className="panel-body">
                <Scrollspy
                  className="list-unstyled list-side-menu"
                  items={['article-details', 'seo-settings', 'custom-fields']}
                  offset={-200}
                  currentClassName="active"
                >
                  <li className="active">
                    <button
                      type="button"
                      className="link-reset btn-chromeless"
                      onClick={() => handleSubNavClick('article-details')}
                    >
                      Article details
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="link-reset btn-chromeless"
                      onClick={() => handleSubNavClick('seo-settings')}
                    >
                      SEO settings
                    </button>
                  </li>
                  {customFields.length > 0 && isCustomFieldsInitialized && (
                    <li>
                      <button
                        type="button"
                        className="link-reset btn-chromeless"
                        onClick={() => handleSubNavClick('custom-fields')}
                      >
                        Custom fields
                      </button>
                    </li>
                  )}
                </Scrollspy>
              </div>
            </div>
          </AutoAffix>
        </div>
        <div className="col-sm-10">
          <DetailsBlock readOnly />
          <CustomFieldsBlock isPreview />
        </div>
      </div>
    </div>
  );
}

export default StoryPreviewSettings;
