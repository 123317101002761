import { createPortal } from 'react-dom';
import StoryPreviewNavigation from '@/preview/components/StoryPreviewNavigation';

function StoryPreviewNavigationPortal(props) {
  const node = document.getElementById('story-preview-navigation');

  if (node) {
    return createPortal(<StoryPreviewNavigation {...props} />, node);
  }

  return null;
}

export default StoryPreviewNavigationPortal;
