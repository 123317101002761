import { gql } from '@apollo/client';
import { JSX, useMemo } from 'react';
import StoryEditNavigationItem from '@/stories/components/StoryEditNavigationItem';
import useStoryEditorSidebars from '@/stories/hooks/useStoryEditorSidebars';
import Review from '@/reviews/components/Review';
import useFragment from '@/storychief/hooks/useFragment';
import currentUserHasPendingReview from '@/reviews/utility/currentUserHasPendingReview';

const fragments = {
  story: gql`
    fragment StoryPreviewNavigationItemsFragment on Story {
      __typename
      id
      reviews {
        ...Review
      }
    }

    ${Review.fragments.review}
  `,
};

type StoryPreviewNavigationItemsType = {
  storyId: string;
};

function StoryPreviewNavigationItems({ storyId }: StoryPreviewNavigationItemsType): JSX.Element {
  const { data: publicStory } = useFragment({ fragment: fragments.story, id: storyId });

  const isOpenReview = useMemo<boolean>(
    () => currentUserHasPendingReview(publicStory),
    [publicStory],
  );

  const storyEditorSidebars = useStoryEditorSidebars();

  function handleOnApprovalSidebarOpen() {
    storyEditorSidebars.replace({
      id: 'ReviewsSidebar',
      props: {
        modelId: storyId,
        modelType: 'Story',
      },
    });
  }

  return (
    <ul className="nav navbar-nav navbar-center">
      <li>
        <StoryEditNavigationItem url="/content" label="Content" isRoot />
      </li>
      <li className="navbar-text navbar-text-first navbar-text-last hidden-xs">
        <em className="icon-angle-right" />
      </li>
      <li>
        <StoryEditNavigationItem url="/settings" label="Summary" />
      </li>
      {isOpenReview && (
        <>
          <li className="navbar-text navbar-text-first navbar-text-last hidden-xs">
            <em className="icon-angle-right" />
          </li>
          <li>
            <button type="button" onClick={handleOnApprovalSidebarOpen}>
              Pending approval
            </button>
          </li>
        </>
      )}
    </ul>
  );
}

export default StoryPreviewNavigationItems;
